import React, {useEffect, useState} from "react"
// import "../../components/DashboardComponents/dashboard.css"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import LayoutProvider from "../../../../components/Layout"
import RecursosIPComponent from "../../../../components/ReclamosComponents/Recursos/RecursosIP"
import RecursosIFComponent from "../../../../components/ReclamosComponents/Recursos/RecursosIF"
import { navigate } from 'gatsby';

const RecursosPage = ({ location }) => {

  const [Intendencia, setIntendencia] = useState("")
  useEffect(() => {
    const logueado = localStorage.getItem("logueado")
    const requiere_actualizar = localStorage.getItem("requiere_actualizar")
    const intendencia = localStorage.getItem("intendencia")
    

    if(intendencia !== null ){
      setIntendencia(intendencia)

    }

    if (logueado === false || logueado === null || logueado === undefined) {
        navigate("/")
    } else if (requiere_actualizar === true || requiere_actualizar === "true") {
        navigate("/inicio/editar-perfil/")
    }
  }, [])

  return (
    <LayoutProvider title="Agregar Recursos" location={location}>
      {/* <p className="title-dashboard-ss">
        Agregar recursos contra Reclamante o Representante
      </p> */}

      <Container fluid className="container-dashboard-ss">
        <Row>
          {Intendencia === "IF" ? (
            <RecursosIFComponent from={'detalle'}/>
          ) : (
            <RecursosIPComponent from={'detalle'}/>
          )}
        </Row>
      </Container>
    </LayoutProvider>
  )
}

export default RecursosPage
